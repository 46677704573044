.FormBaseScreen{
    min-height: 67vh;
    width: 50vw;
    border-radius: 50px;
    background: linear-gradient(45deg,#8176FF,5%,#100e1f 90%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 5px 5px 15px #000;
    margin-bottom: 10vh;
  }
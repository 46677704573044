.PartnerAnimaiton{
    animation: FadeIntoFrame 2s ease-out;
}

.PartnerStuff{
    animation: FadeIntoFrame 2s ease-out;
}

@keyframes FadeIntoFrame{
    0%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
    }
}

.FormButton{
    margin-top: 30px;
    font-size: 1.3vw;
    width: 60%;
    padding: 13px;
    border-radius: 4px; 
    border: none;
    cursor: pointer;
    color: #000000;
    background-color: #8176FF;
}

.FadeInAnimation{
    animation:  crossFade 1s ease-out 1.2s;
    animation-fill-mode: forwards;
    opacity: 0%;
}

@keyframes crossFade{
    0%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
    }
}

.CheckAnimation{
    animation:  flip 1s ease-out .6s;
    animation-fill-mode: forwards;
    filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%);
    width: 8vw;
    height: 8vw;
    opacity: 0%;
}

@keyframes flip{
    0%{
        opacity: 0%;
        transform: rotateY(0deg);
    }
    100%{
        opacity: 100%;
        transform: rotateY(360deg);
    }
}

.FinishBubble{
    border-radius: 50%;
    background-color: #0cba1e;
    opacity: 0%;
}

.BubbleFlyOne{
    animation: bubbleFlyOne .2s ease-out .68s;
    animation-fill-mode: forwards;
}

@keyframes bubbleFlyOne{
    0%{
        transform: translate(280%, -170%);
        opacity: 0%;
    }
    100%{
        transform: translate(0%, 0%);
        opacity: 100%;
    }
}

.BubbleFlyTwo{
    animation: bubbleFlyTwo .2s ease-out .68s;
    animation-fill-mode: forwards;
}

@keyframes bubbleFlyTwo{
    0%{
        transform: translate(-100%, -170%);
        opacity: 0%;
    }
    100%{
        transform: translate(0%, 0%);
        opacity: 100%;
    }
}

.BubbleFlyThree{
    animation: bubbleFlyThree .2s ease-out .68s;
    animation-fill-mode: forwards;
}

@keyframes bubbleFlyThree{
    0%{
        transform: translate(-100%, 0%);
        opacity: 0%;
    }
    100%{
        transform: translate(0%, 0%);
        opacity: 100%;
    }
}

.FinishAnimation{
    animation: bubblePop .7s ease-out;
    animation-fill-mode: forwards;
}

@keyframes bubblePop{
    0%{
        opacity: 0%;
    }
    100%{
        background-color: #0cba1e;
        width: 15vw;
        height: 15vw;
        opacity: 100%;
    }
}

.SubscriptionOptions{
    margin-top: 15px;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
}

.BoldSpaced{
    font-weight: 600;
    letter-spacing: 1.5px;
}

.FinalInput{
    background: none;
    outline: none;
    color: white;
    border: none;
    margin: 0px;
    width: 40%;
}

.FormInput:-webkit-autofill,
.FormInput:-webkit-autofill:hover, 
.FormInput:-webkit-autofill:focus, 
.FormInput:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    box-shadow: 0 0 0 30px #ffffff inset !important;
}

.AccountText{
    margin: 0px;
    color: #ffffff;
}

.FormInput{
    padding: 15px;
    width: 60%;
    border-radius: 3px;
    border: none;
    outline: none;
    margin-top: 30px;
}

.FormInputSelect{
    color: #717171;
    padding: 15px;
    width: 60%;
    border-radius: 3px;
    border: none;
    outline: none;
    margin-top: 30px;
}

.MultiFormInput{
    width: 24vw !important;
    border-radius: 3px;
    border: none;
    outline: none;
    margin-top: 30px;
}

.searchWrapper{
    padding: 10px !important;
    color: black !important;
}

.chip{
    background: #6860d0 !important;
}

.FormInputBGC{
    padding: 15px;
    width: 60%;
    border-radius: 3px;
    border: none;
    outline: none;
    margin-top: 15px;
}

.searchWrapper{
    background-color: white !important;
    padding: 10px;
}

.OptionText{
    color: #000000;
}

.ButtonContainer{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RSPBprogressBar{
    width: 85%;
    height: 2px;
    border-radius: 20px;
}

.RSPBprogression{
    height: 2px;
    border-radius: 20px;
}
.PathScreen{
    height: 67vh;
    width: 50vw;
    position:absolute;
    left: 25%;
    top: 17%;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.temp{
    box-shadow: 5px 5px 15px #000;
    background: linear-gradient(45deg,#8176FF,5%,#100e1f 90%);
}

.PathOption{
    width: 45%;
    height: 90%;
    border-radius: 15px;
    background: linear-gradient(0deg,#8176FF,#08070f 92%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 5px 10px #000;
    text-decoration: none;
}
.MainButton{
    margin-top: 30px;
    font-size: 1.3vw;
    width: 30%;
    padding: 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    color: #000000;
    background-color: #8176FF;
    box-sizing: content-box;
}
.NavTag{
    margin-right: 40px;
    font-size: .9rem;
}
  
.NavTag:hover{
    cursor: pointer;
}

.NavTagLogin{
    border-radius: 20px;
    background-color: #8176FF;
    width: 90px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.Home{
    color: #FFFFFF;
    width: 100vw;
    height: 85vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.HomeButton{
    background-color: #6860d0;
    border: none;
    border-radius: 35px;
    color: #FFFFFF;
    padding: 15px;
    width: 50%;
    margin-right: 10px;
    margin-left: 10px;
    text-decoration: none;
    text-align: center;
}

.HomeButton:hover{
    cursor: pointer;
}

.FadeIn{
    animation: FadeIn 2s ease-out;
}

.FadeInDiv{
    animation: FadeInDiv 2s ease-out ;
}
.FadeInDivDelay{
    animation: FadeInDiv 3s ease-out;
}
.FadeInDivDelayMore{
    animation: FadeInDiv 4s ease-out;
}

@keyframes FadeIn {
    0%{
        transform: translatex(-30%);
        opacity: 0%;
    }
    100%{
        transform: translateX(0%);
        opacity: 100%;
    }
}

@keyframes FadeInDiv {
    0%{
        transform: translatey(-20%);
        opacity: 0%;
    }
    100%{
        transform: translatey(0%);
        opacity: 100%;
    }
}
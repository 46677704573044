.ServiceSelect{
    padding: 15px;
    border-radius: 25px;
    width: 20%;
    margin-bottom: 30px;
    background: #8176FF;
    outline: none;
    border: none;
    color: white;
}

.ServiceCost{
    outline: none;
    border: none;
    width: 80%;
    background: #8176FF;
    color: white;
}

.ServiceInput{
    width: 80%;
    margin-bottom: 30px;
    background: none;
    outline: none; 
    border: none; 
    border-bottom: 2px solid #8176FF;
    color:white;
    font-size: 1rem;
    margin-left: 4vw;
    resize: none;
    height: 10vh;
}

.ServiceInput::placeholder{
    color:rgba(255, 255, 255, 0.808);
}

.ServiceError{
    width: 60%;
    margin-bottom: 30px;
    background: none;
    outline: none; 
    border: none; 
    border-bottom: 2px solid #8176FF;
    color:white;
    font-size: 1.2rem;
    letter-spacing: 1px;
    margin-left: 4vw;
}

.ServiceError::placeholder{
    color:rgba(0, 0, 0, 0.808);
}
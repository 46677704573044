.LayoutOption{
    height: 8vh;
    width: 50%;
    margin: 0px 20px 20px 0px; 
    color: white; 
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ServiceContainer{
    border-radius: 30px;
    width: 74vw;
    height: 95vh;
    background: #1B1B1B;
    box-shadow: 0px 3.45235px 44.0175px #000000;
    position: absolute;
    top: 2.5%;
    right: 3%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;
    justify-content: flex-start;
}

.DashboardContainer{
    display: flex;
    flex-direction: row;
    margin-left: 5vw;
    margin-top: 10px;
    align-items: center;
}

.SelectOptions{
    padding: 7px;
    border-radius: 25px;
    width: 40%;
    margin-bottom: 15px;
    background: #8176FF;
    outline: none;
    border: none;
    color: white;
}

.DashboardDelete{
    border: solid 2px #b94848; 
    background-color: transparent;
    transition: background-color .3s;
}

.DashboardDelete:hover{
    background-color: #b94848; 
}

.DashboardCreate{
    border: solid 2px #44940f; 
    background-color: transparent;
    transition: background-color .3s;
}

.DashboardCreate:hover{
    background-color: #44940f; 
}

.DashboardSave{
    border: solid 2px #8176FF;
    background-color: transparent;
    transition: background-color .3s;
}

.DashboardSave:hover{
    background-color: #8176FF; 
}

.DashboardButton{
    justify-content: center;
    padding: 10px; 
    border-radius: 25px;
    width: 30%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    align-self: center;
    cursor: pointer;
}

.InputOptions{
    padding: 7px;
    border-radius: 25px;
    width: 30%;
    margin-bottom: 15px;
    background: #8176FF;
    outline: none;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    align-self: center;
}

.ServiceOption{
    width: 45%;
    height: 22.5%;
    background: linear-gradient(32deg, rgba(104, 96, 208, 0.85) , rgb(11, 10, 23) 81.14%);
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    margin-left: 45px;
    align-items: center;
}

.AccountBase{
    height: 80vh;
    width: 50vw;
    border-radius: 50px;
    background:#1B1B1B; 
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 5px 5px 15px #000;
    overflow-y: auto;
}


.WelcomeScreen{
    height: 67vh;
    width: 50vw;
    position:absolute;
    left: 25%;
    top: 17%;
    border-radius: 50px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 5px 5px 15px #000;
  }
  
  .FloatGuy{
    /*
    animation-timing-function: linear;
    animation-name: float;
    -o-animation-name: float;
    -webkit-animation-name: float;
    animation-duration: 6s;
    -o-animation-duration: 6s;
    -webkit-animation-duration: 6s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    */
    z-index: 1;
    position: absolute;
  }
  
  @keyframes float{
    0%  {transform:translateY(0px);}
    25%  {transform: translateY(-10px);}
    50%  {transform: translateY(0px);}
    75%  {transform: translateY(10px);}
    100% {transform: translateY(0px);}
  }

  @keyframes slideOut{
    0%  {transform: translateX(0px); opacity: 100;}
    100%{transform: translate(-200px); opacity: 0;}
  }

  @keyframes slideIn{
    0%  {transform: translateX(250px); opacity: 0;}
    100%{transform: translate(0px); opacity: 100;}
  }
  
  .Input{
    padding: 13px;
    border: 2px solid black;
    outline: none;
    color: #152235;
    width: 100%;
  }

  .InputContainer{
    display: flex;
    justify-content: center;
    width: 65%;
    padding: 0px 10px 15px 4px; 
    margin: 5px 0px 7.5px 0px;
    border-radius: 15px;
  }
  
  .Input::placeholder{
    letter-spacing: 2px;
    color: #152235;
    font-weight: bold;
  }
  
  .LoginButton{
    background: linear-gradient(256.26deg, #6860D0 9.11%, #151229 85.38%);
    width: 65%;
    height: 100%;
    border-radius: 4px; 
    color: #FFFFFF; 
    border: none; 
    padding: 15px;
    letter-spacing: 1px; 
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .BackgroundContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 93%;
    background: linear-gradient(#0b0a16,83%,#8176FF);
    border-radius: 50px;
    position: relative;
  }
  